// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vossloh-logo {
    height: 55px;
    vertical-align: middle;
}

.vossloh-links {
    display: flex;
    margin-left: 16px;
    font-weight: 600;
    font-size: 14px;
}

.vossloh-links a {
    color: rgb(36, 41, 46);
    margin-left: 16px;
}

.vossloh-links a:hover {
    color: #33ae33;
}

.vossloh-header {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    font-family: "Titillium Web", sans-serif;
}

.vossloh-header span {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, .87);
    margin-left: 12px;
    letter-spacing: normal;
}`, "",{"version":3,"sources":["webpack://./public/app/core/components/AppChrome/TopBar/VosslohHeader.css"],"names":[],"mappings":"AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');\n\n.vossloh-logo {\n    height: 55px;\n    vertical-align: middle;\n}\n\n.vossloh-links {\n    display: flex;\n    margin-left: 16px;\n    font-weight: 600;\n    font-size: 14px;\n}\n\n.vossloh-links a {\n    color: rgb(36, 41, 46);\n    margin-left: 16px;\n}\n\n.vossloh-links a:hover {\n    color: #33ae33;\n}\n\n.vossloh-header {\n    width: 100%;\n    height: 55px;\n    display: flex;\n    align-items: center;\n    justify-content: left;\n    position: relative;\n    font-family: \"Titillium Web\", sans-serif;\n}\n\n.vossloh-header span {\n    font-size: 20px;\n    font-weight: 600;\n    color: rgba(0, 0, 0, .87);\n    margin-left: 12px;\n    letter-spacing: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
